import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: null,
    permissions: {}, // Initialize as an empty object
    error: null
}

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        authActionStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        authActionSuccess: (state, action) => {
            state.loading = false;
            state.data = action?.payload.data;
            state.permissions = action.payload.permissions; // Save mapped permissions
        },
        authActionFailure: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        authActionLogout: (state) => {
            state.data = null;
            state.error = null;
        }
    },
});

export const {
    authActionStart,
    authActionSuccess,
    authActionFailure,
    authActionLogout
} = authSlice.actions;

export default authSlice.reducer;