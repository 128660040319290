import Swal from 'sweetalert2';
import * as ReactDOMServer from 'react-dom/server';
import {
    CCol,
    CFormInput,
    CFormLabel
} from '@coreui/react-pro';
import fetchRequest from 'src/network';
import { getBookingStatusString, isNotNull, removeBracketId } from './constants';
import moment from 'moment';
import './common/css/sweetPopup.css';
import { QRCodeSVG } from 'qrcode.react';


const swalDelete = (deleteFunction, props) => {
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
    }).then((result) => {
        if (result.isConfirmed) {
            deleteFunction(props);
            Swal.fire({
                title: "Deleted!",
                text: "Data has been deleted.",
                icon: "success"
            });
        }
    })
};

const swalCancel = (cancelFunction, props) => {
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel it!",
        customClass: {
            confirmButton: "btn btn-md text-white",
            cancelButton: "btn btn-md text-white"
        },
    }).then((result) => {
        if (result.isConfirmed) {
            let res = cancelFunction(props);
            if (res) {
                Swal.fire({
                    title: "Cancelled!",
                    text: "Entry has been cancelled!",
                    icon: "success"
                });
            }
        }
    })
};

const swalCancelWithReason = (cancelFunction, props) => {
    Swal.fire({
        title: "Are you sure?",
        text: "Enter reason for booking cancellation!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel it!",
        input: "text",
        showLoaderOnConfirm: true,
        customClass: {
            confirmButton: "btn btn-md text-white",
            cancelButton: "btn btn-md text-white"
        },
        preConfirm: async (data) => {
            if (!isNotNull(data)) {
                Swal.showValidationMessage("Cancel reason is required!");
            }
        }
    }).then((result) => {
        if (result.isConfirmed) {
            if (isNotNull(result?.value)) {
                let res = cancelFunction(props, result.value);
                if (res) {
                    Swal.fire({
                        title: "Cancelled!",
                        text: "Entry has been cancelled!",
                        icon: "success"
                    });
                }
            }
        }
    })
};

const swalError = (errMsg) => {
    Swal.fire({
        icon: "error",
        title: errMsg?.message ?? "Error!",
        text: errMsg?.error_message ?? "Something went wrong!",
        confirmButtonText: 'Okay',
        customClass: {
            confirmButton: "btn btn-md text-white bg-danger"
        }
    });
}

const swalWarning = (message) => {
    Swal.fire({
        icon: "warning",
        title: message?.title ?? "Warning!",
        text: message?.message ?? "Something went wrong!",
        confirmButtonText: 'Okay',
        customClass: {
            confirmButton: "btn btn-md text-white bg-warning"
        }
    });
}

const swalSuccess = (message) => {
    let timerInterval;
    Swal.fire({
        icon: "success",
        title: "Success!",
        text: message ?? "Success!",
        timer: 2000,
        showConfirmButton: false,
        willClose: () => {
            clearInterval(timerInterval);
        },
        customClass: {
            confirmButton: "btn btn-md text-white bg-success"
        }
    });
};

const swalSetPassword = async ({
    title = "Set Password",
    userId = 0
}) => {

    await Swal.fire({
        title: title,
        html: ReactDOMServer.renderToStaticMarkup(
            <div className="p-3">
                <CCol>
                    <CFormLabel className='text-start w-100'>Password<span className='text-danger ms-2'>*</span></CFormLabel>
                    <CFormInput
                        type="password"
                        id="password1"
                        placeholder='Enter password'
                        required
                        className="border border-secondary"
                    />
                </CCol>
                <CCol className="mt-3">
                    <CFormLabel className='text-start w-100 mt-3'>Confirm Password<span className='text-danger ms-2'>*</span></CFormLabel>
                    <CFormInput
                        type="password"
                        id="password2"
                        placeholder='Enter confirm password'
                        required
                        className="border border-secondary"
                    />
                </CCol>
            </div>
        ),
        focusConfirm: false,
        confirmButtonText: "Save",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        customClass: {
            confirmButton: "btn btn-md text-white bg-success",
            cancelButton: 'btn btn-md text-white bg-warning'
        },
        showLoaderOnConfirm: true,
        preConfirm: async (data) => {
            let body = {
                "password": document.getElementById("password1")?.value,
                "confirm_password": document.getElementById("password2")?.value
            }
            if (!isNotNull(body?.password) && !isNotNull(body?.confirm_password)) {
                Swal.showValidationMessage("Fields must not be blank!");
            } else {
                fetchRequest?.post(`/auth/set-password/${userId}`, body)
                    .then((response) => {
                        if (response?.status_code === 200) {
                            swalSuccess(response?.message);
                        } else {
                            swalError(response);
                        }
                    })
                    .catch((error) => {
                        swalError(error);
                    });
            }
        }
    });
}

const swalStatusUpdate = (id, status = "", statusSetter, setIsRefresh, isRefresh) => {
    Swal.fire({
        title: "Status Remarks",
        text: "",
        input: "text",
        inputAttributes: {
            autocapitalize: "off"
        },
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update Status",
        showLoaderOnConfirm: true,
        customClass: {
            confirmButton: "btn btn-md text-white",
            cancelButton: "btn btn-md text-white"
        },
        preConfirm: async (data) => {
            if (isNotNull(data)) {
                try {
                    let body = {
                        status: status,
                        status_remarks: data
                    }
                    fetchRequest.post(`/booking/${id}/status-update`, body)
                        .then((response) => {
                            if (response?.status_code === 200) {
                                statusSetter(status);
                                swalSuccess(response?.message);
                                setIsRefresh(!isRefresh);
                            } else {
                                swalError(response);
                            }
                        })
                        .catch((error) => {
                            swalError(error);
                        });
                } catch (error) {
                    swalError(error);
                }
            } else {
                Swal.showValidationMessage("A reason for this action is required!");
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
    });
}

const swalStatusLogs = (data = "", statusColor) => {
    Swal.fire({
        title: "Status Logs",
        icon: "none",
        html: ReactDOMServer.renderToStaticMarkup(
            <table className='modal-table'>
                <tr className='bg-secondary text-light fix-pos modal-tr'>
                    <th className='modal-th'>Date & Time</th>
                    <th className='modal-th'>Status</th>
                    <th className='modal-th'>Status Remarks</th>
                    <th className='modal-th'>Update Type</th>
                    <th className='modal-th'>User</th>
                </tr>
                {
                    data?.map((i, idx) => (
                        <tr className='modal-tr'>
                            <td className='modal-td'><small>{moment(i?.datetime).format('ll hh:mm')}</small></td>
                            <td className='modal-td'><code><small style={{ color: statusColor(i?.status) }}><b>{getBookingStatusString(i?.status)}</b></small></code></td>
                            <td className='modal-td'><small>{i?.status_remarks}</small></td>
                            <td className='modal-td'><small>{i?.update_type}</small></td>
                            <td className='modal-td'><small>{removeBracketId(i?.user_details)}</small></td>
                        </tr>
                    ))
                }
            </table>
        ),
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Close',
        customClass: {
            confirmButton: "btn btn-md text-white bg-danger",
            popup: "w-h-75",
        },
        showClass: {
            popup: "aminate__animated animated_fadeInDown"
        },
        hideClass: {
            popup: "animate__animated animate__fadeOutUp"
        }
    });
}


const swalQRCode = (data = "") => {
    Swal.fire({
        icon: "none",
        title: "QR Code",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Download",
        html: ReactDOMServer.renderToStaticMarkup(
            <div className='text-center'>
                <span ><QRCodeSVG value={data} size={256} id={"svg-qr"} /></span>
            </div>
        ),
        customClass: {
            confirmButton: "btn btn-md text-white",
            cancelButton: "btn btn-md text-white"
        },
        preConfirm: () => {
            var svg = document.getElementById("svg-qr");

            //get svg source.
            var serializer = new XMLSerializer();
            var source = serializer.serializeToString(svg);
            //convert svg source to URI data scheme.
            var image = new Image();
            image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);

            image.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                var context = canvas.getContext('2d');
                context.drawImage(image, 0, 0);

                //create a png image from the canvas
                var pngFile = canvas.toDataURL('image/png');

                //create a download link and click it
                var downloadLink = document.createElement('a');
                downloadLink.href = pngFile;
                downloadLink.download = 'qrcode.png';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            };
        }
    });
};




export {
    swalDelete,
    swalError,
    swalWarning,
    swalSuccess,
    swalSetPassword,
    swalCancel,
    swalCancelWithReason,
    swalStatusUpdate,
    swalStatusLogs,
    swalQRCode
};